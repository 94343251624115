<template>
  <!--
    The view for the Vacation Report generation
  -->
  <VacationReport />
</template>

<script>
export default {
  name: "VacationReportView",
  components: {
    VacationReport: () => import('@/components/CustomerWorklog/VacationReport.vue')
  },
  metaInfo () {
    return {
      title: 'Vacation Report'
    }
  }
}
</script>
